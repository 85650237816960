import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Plus } from "react-feather";
import Title from "../../../../components/Title";
import { Container } from "../../../../components/Container";
import { SimpleLoading } from "../../../../components/Loading";

import ListEntry from "../../components/ListEntry";

const EventList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasOrganizations, setHasOrganizations] = useState(true);
  useEffect(() => {
    fetch(`/v1/events?editable=true`, {
      headers: new Headers({ Authorization: localStorage.getItem("token") })
    })
      .then(res => res.json())
      .then(data => setData(data))
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    fetch(`/v1/organizations?editable=true`, {
      headers: new Headers({ Authorization: localStorage.getItem("token") })
    })
      .then(res => res.json())
      .then(data => setHasOrganizations(data.length > 0))
      .then(console.log(hasOrganizations));
  }, []);
  return (
    <div>
      <Title
        title="Veranstaltungen"
        subtitle="Erstelle und verwalte Veranstaltungen."
        key="title"
        create={
          <Link
            to="/events/create"
            onClick={() => {
              if (!hasOrganizations) {
                alert(
                  "Um eine Veranstaltung zu erstellen musst du zuerst eine Seite verwalten."
                );
              }
            }}
          >
            <Plus />
          </Link>
        }
      />
      <Container key="content">
        <SimpleLoading isLoading={isLoading} />
        {data.map(entry => (
          <ListEntry event={entry} key={entry.id} />
        ))}
      </Container>
    </div>
  );
};

export default EventList;
