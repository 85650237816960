import React, { useState } from "react";
import "./input.scss";

const LongTextInput = props => {
  const {
    value,
    onChange,
    label,
    name,
    minLength = 0,
    maxLength = 255
  } = props;
  const [hasValue, setHasValue] = useState(
    value !== null && value !== undefined
  );

  return (
    <div>
      <h4>{label}</h4>
      <div className="inputField longText">
        <textarea
          name={name}
          minLength={minLength}
          maxLength={maxLength}
          required={minLength > 0}
          onChange={e => {
            setHasValue(true);
            onChange(e);
          }}
          className={hasValue ? "hasValue " : ""}
        >
          {value}
        </textarea>
      </div>
    </div>
  );
};

export default LongTextInput;
