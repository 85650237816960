import React, { useState } from "react";
import { UploadCloud } from "react-feather";

const ImageInput = ({
  className,
  previewSrc,
  label,
  cb,
  upload,
  acceptedTypes
}) => {
  const [hasValue, setHasValue] = useState(false);
  const style = {
    backgroundImage: `url(${previewSrc})`
  };
  return (
    <div className={`imageInput ${className}`} style={style}>
      <label class="image-upload">
        <input
          accept={acceptedTypes}
          type="file"
          onChange={e => {
            setHasValue(true);
            cb(e);
          }}
        />
        <div className="label-container">
          <UploadCloud />
          {label}
        </div>
        <br />
        {hasValue && (
          <button className="submit" onClick={upload}>
            Hochladen
          </button>
        )}
      </label>
    </div>
  );
};

export default ImageInput;
