import React, { useState } from "react";
import { TextInput } from "../../../../components/Input";
import "./index.scss";
import { handleInput } from "../../../../utils/index";

const ForgotPassword = () => {
  const [form, setForm] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    fetch("/v1/account/password?reset=true", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify({ mail: form.mail })
    }).then(() => {
      setIsSubmited(true);
    });
  };
  return [
    <form onSubmit={handleSubmit}>
      <TextInput
        label="E-Mail"
        name="mail"
        value={form.mail}
        onChange={e => handleInput(e, form, setForm)}
      />
      <input type="submit" />
    </form>,
    <div>
      {isSubmited && (
        <p>
          Du solltest in den nächsten Minuten eine E-Mail zum zurücksetzten
          deines Passworts bekommen!
        </p>
      )}
    </div>
  ];
};

export default ForgotPassword;
