import React from "react";
import "./index.scss";

const Container = ({ children }) => {
  return <div className="container">{children}</div>;
};

const Flex = ({
  alignItems = "start",
  justifyContent = "center",
  direction = "row",
  children,
  className
}) => {
  const styles = {
    alignItems: alignItems,
    justifyContent: justifyContent,
    flexDirection: direction
  };
  return (
    <div className={`container flex ${className}`} style={{ ...styles }}>
      {children}
    </div>
  );
};

export { Container, Flex };
