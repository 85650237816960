import React from "react";
import { TextInput } from "../../../../../components/Input";
import { handleInput } from "../../../../../utils/index";

const Location = ({ state, setState }) => {
  const location = state.location || {};

  return (
    <div id="location">
      <TextInput
        value={location.name || ""}
        name="location.name"
        onChange={e => {
          handleInput(e, state, setState);
        }}
        label="Name des Veranstaltungsorts"
      />
      <TextInput
        value={location.street || ""}
        name="location.street"
        onChange={e => {
          handleInput(e, state, setState);
        }}
        label="Straße"
      />
      <TextInput
        value={(location && location.houseNumber) || ""}
        name="location.houseNumber"
        onChange={e => {
          handleInput(e, state, setState);
        }}
        label="Hausnummer"
      />
      <TextInput
        value={(location && location.zip) || ""}
        name="location.zip"
        onChange={e => {
          handleInput(e, state, setState);
        }}
        label="Postleitzahl"
      />
      <TextInput
        value={(location && location.city) || ""}
        name="location.city"
        onChange={e => {
          handleInput(e, state, setState);
        }}
        label="Ort"
      />
    </div>
  );
};

export default Location;
