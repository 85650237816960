import Flatpickr from "react-flatpickr";
import React, { useState } from "react";
import "flatpickr/dist/themes/material_green.css";
import { German } from "flatpickr/dist/l10n/de.js";

const DateInput = ({ value, onChange, label, name }) => {
  const [hasValue, setHasValue] = useState(
    value !== null && value !== undefined
  );
  return (
    <div className="inputField">
      <Flatpickr
        value={value}
        name={name}
        onChange={(date, dateString) => {
          setHasValue(true);
          onChange({ target: { name: name, value: dateString } });
        }}
        className={hasValue ? "hasValue " : ""}
        options={{
          time_24hr: true,
          enableTime: true,
          dateFormat: "Y-m-d H:i",
          clickOpens: true,
          minuteIncrement: 1,
          locale: German
        }}
      />
      <label>{label}</label>
    </div>
  );
};

export default DateInput;
