import React from "react";
import { Link, useLocation } from "react-router-dom";
import { List, Calendar, Home, LogOut, Settings } from "react-feather";
import "./index.scss";

const Navbar = () => {
  let location = useLocation();
  return (
    <nav className="nav-component">
      <ul>
        <li className={location.pathname === "/" ? "active " : ""}>
          <Link to="/">
            <Home />
            Home
          </Link>
        </li>
        <li
          className={
            location.pathname.includes("/organizations") ? "active " : ""
          }
        >
          <Link to="/organizations">
            <List />
            Seiten
          </Link>
        </li>
        <li className={location.pathname.includes("/events") ? "active " : ""}>
          <Link to="/events">
            <Calendar />
            Veranstaltungen
          </Link>
        </li>
        <li
          className={location.pathname.includes("/settings") ? "active " : ""}
        >
          <Link to="/settings">
            <Settings />
            Einstellungen
          </Link>
        </li>
        <li>
          <Link to="/logout">
            <LogOut />
            Logout
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
