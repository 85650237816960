import React from "react";
import { Route, useRouteMatch } from "react-router-dom";

import EventList from "./scenes/EventList";
import EventEdit from "./scenes/EventEdit";
import EventAdd from "./scenes/EventAdd";

const Events = () => {
  const match = useRouteMatch();
  return [
    <Route exact path={match.path} component={EventList} key={"default"} />,
    <Route
      exact
      strict
      path={`${match.path}/create`}
      component={EventAdd}
      key={"add"}
    />,
    <Route path={`${match.path}/edit/:id`} component={EventEdit} key={"edit"} />
  ];
};

export default Events;
