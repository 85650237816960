import React, { useState } from "react";
import { handleInput } from "../../../../../../utils/index";

import { TextInput, SelectInput } from "../../../../../../components/Input";
import Submit from "../../../../../../components/Button/Submit";

const AddMember = ({ cb, isSubmitting }) => {
  const [form, setForm] = useState({});
  const handleSubmit = event => {
    event.preventDefault();
    cb(form.mail, form.accessLevel);
  };
  return (
    <div>
      <h3>Neues Mitglied hinzufügen</h3>
      <form onSubmit={handleSubmit}>
        <TextInput
          label="E-Mail Adresse des neuen Mitglieds"
          onChange={e => handleInput(e, form, setForm)}
          name="mail"
          value={form.mail}
        />
        <SelectInput
          label="Zugriffsberechtigung"
          onChange={e => handleInput(e, form, setForm)}
          name="accessLevel"
          value={form.accessLevel}
          options={[
            { name: "ADMIN", id: "ADMIN" },
            { name: "MODERATOR", id: "MODERATOR" }
          ]}
        />
        <Submit title={"Mitglied hinzufügen"} isSubmitting={isSubmitting} />
      </form>
    </div>
  );
};

export default AddMember;
