import React, { useState, useEffect } from "react";
import Title from "../../../../components/Title";
import { Container, Flex } from "../../../../components/Container";
import {
  TextInput,
  DateInput,
  RichTextInput,
  LongTextInput,
  SelectInput
} from "../../../../components/Input";
import { handleInput } from "../../../../utils/index";
import Location from "../components/Location";
import { toast } from "react-toastify";
import Submit from "../../../../components/Button/Submit";

const EventAdd = ({ history }) => {
  const [form, setForm] = useState({
    location: {
      name: "",
      street: "",
      houseNumber: "",
      zip: "32609",
      city: "Hüllhorst"
    }
  });
  const [organizations, setOrganizations] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetch(`/v1/organizations?editable=true`, {
      headers: new Headers({ Authorization: localStorage.getItem("token") })
    })
      .then(res => res.json())
      .then(data => setOrganizations(data));
  }, []);

  const handleSubmit = async event => {
    setIsSubmitting(true);
    event.preventDefault();
    fetch("/v1/events", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token")
      }),
      body: JSON.stringify({ ...form })
    })
      .then(res => {
        setIsSubmitting(false);
        if (res.status === 200) {
          return res.json();
        } else {
          Promise.reject();
        }
      })
      .catch(error => {
        toast.error(
          "Ein Fehler ist aufgetreten. Die Seite konnte nicht erstellt werden."
        );
      })
      .then(data => {
        history.push(`/events/edit/${data.id}`);
      });
  };
  return (
    <div>
      <Title
        title="Erstelle eine neue Veranstaltung"
        subtitle="Erstelle eine neue Veranstaltung für eine Organisation (Verein, Partei, Firma) die du vertrittst."
      />
      <Container>
        <form onSubmit={handleSubmit}>
          <h3>Allgemeine Informationen</h3>
          <Flex alignItems="start" className="shadowbox">
            <div style={{ flex: "0 50%" }}>
              <TextInput
                value={form.title}
                name="title"
                onChange={e => handleInput(e, form, setForm)}
                label="Titel der Veranstaltung"
              />
              <TextInput
                value={form.urlToExternalPage}
                name="urlToExternalPage"
                onChange={e => handleInput(e, form, setForm)}
                label="Link zur Veranstaltungsseite"
              />
              <DateInput
                value={form.start}
                name="start"
                onChange={e => handleInput(e, form, setForm)}
                label="Start der Veranstaltung"
              />
              <DateInput
                value={form.end}
                name="end"
                onChange={e => handleInput(e, form, setForm)}
                label="Ende der Veranstaltung"
              />
            </div>
            <div style={{ flex: "0 50%" }}>
              <LongTextInput
                value={form.shortDescription}
                name="shortDescription"
                onChange={e => handleInput(e, form, setForm)}
                label="Kurzbeschreibung der Veranstaltung"
              />
            </div>
          </Flex>
          <h3>Veranstaltungsbeschreibung</h3>
          <RichTextInput
            value={form.fullDescription}
            name="fullDescription"
            onChange={e => handleInput(e, form, setForm)}
            label="Beschreibung"
          />
          <h3>Veranstaltungsort</h3>
          <Flex className="shadowbox" justifyContent="start">
            <Location state={form} setState={setForm} />
          </Flex>
          <h3>Veranstalter</h3>
          <Flex className="shadowbox">
            <SelectInput
              value={form.organizedBy}
              name="organizedBy"
              onChange={e => handleInput(e, form, setForm)}
              label="Veranstalter"
              options={organizations.map(org => {
                return { name: org.fullName, id: org.id };
              })}
            />
          </Flex>
          <Submit
            title={"Veranstaltung erstellen"}
            isSubmitting={isSubmitting}
          />
        </form>
      </Container>
    </div>
  );
};

export default EventAdd;
