import React, { useState, useEffect } from "react";
import Title from "../../../../components/Title";
import { Container, Flex } from "../../../../components/Container";
import { SimpleLoading } from "../../../../components/Loading";
import { TextInput, RichTextInput, ImageInput } from "../../../../components/Input";
import "react-quill/dist/quill.snow.css";
import AddMember from "./components/AddMember";
import "./index.scss";

import { handleInput } from "../../../../utils";

import { useParams } from "react-router-dom";
import { Trash2 } from "react-feather";
import { toast } from "react-toastify";
import Submit from "../../../../components/Button/Submit";

const OrganizationEdit = ({ history }) => {
  const { id } = useParams();
  const [org, setOrg] = useState([]);
  const [headerImage, setHeaderImage] = useState([]);
  const [logoImage, setLogoImage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [
    isSubmittingGeneralInformation,
    setIsSubmittingGeneralInformation
  ] = useState(false);
  const [isSubmittingMember, setIsSubmittingMember] = useState(false);
  useEffect(() => {
    fetch(`/v1/organizations/${id}?editable=true`, {
      headers: new Headers({ Authorization: localStorage.getItem("token") })
    })
      .then(res => res.json())
      .then(data => setOrg(data))
      .then(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const addMember = (mail, accessLevel) => {
    setIsSubmittingMember(true);
    fetch(`/v1/organizations/${id}/members`, {
      headers: new Headers({
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json"
      }),
      method: "PUT",
      body: JSON.stringify({
        mail: mail,
        accessLevel: accessLevel
      })
    }).then(res => {
      setIsSubmittingMember(false);
      if (res.status === 200) {
        toast.success("Mitglied hinzugefügt.");
      } else {
        toast.error(
          "Das Mitglied konnte nicht hinzugefügt werden. Ein Fehler ist aufgetreten."
        );
      }
    });
  };

  const removeMember = mail => {
    fetch(`/v1/organizations/${id}/members/${mail}`, {
      headers: new Headers({
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json"
      }),
      method: "DELETE"
    });
  };

  const addHeaderImage = () => {
    var formData = new FormData();
    formData.append("header_image", headerImage);
    fetch(`/v1/organizations/${id}/header_image`, {
      headers: new Headers({
        Authorization: localStorage.getItem("token")
      }),
      method: "POST",
      body: formData
    }).then(res => {
      if (res.status === 200) {
        toast.info("Das Titelbild wurde erfolgreich hochgeladen.");
        setIsLoading(true);
        setIsLoading(false);
      } else {
        toast.error(
          "Beim hochladen des Titelbilds ist ein Fehler aufgetreten."
        );
      }
    });
  };

  const addLogoImage = () => {
    var formData = new FormData();
    formData.append("logo_image", logoImage);
    fetch(`/v1/organizations/${id}/logo_image`, {
      headers: new Headers({
        Authorization: localStorage.getItem("token")
      }),
      method: "POST",
      body: formData
    }).then(res => {
      if (res.status === 200) {
        toast.info("Das Logo wurde erfolgreich hochgeladen.");
        setIsLoading(true);
        setIsLoading(false);
      } else {
        toast.error("Beim hochladen des Logos ist ein Fehler aufgetreten.");
      }
    });
  };

  const deletePage = e => {
    e.preventDefault();
    toast.info(
      `Die Seite ${org.shortName} wird demnächst unwiederruflich gelöscht.`
    );
    fetch(`/v1/organizations/${id}`, {
      headers: new Headers({
        Authorization: localStorage.getItem("token")
      }),
      method: "DELETE"
    }).then(() => history.push("/organizations"));
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsSubmittingGeneralInformation(true);
    fetch(`/v1/organizations/${id}`, {
      headers: new Headers({
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json"
      }),
      method: "PUT",
      body: JSON.stringify({
        ...org
      })
    }).then(() => {
      toast.success("Seite wurde aktualisiert.");
      setIsSubmittingGeneralInformation(false);
    });
  };
  return (
    <div>
      <Title
        title={
          isLoading
            ? "Verwalte die Seite "
            : `Verwalte die Seite ${String.fromCharCode(187)} ${
                org.shortName
              }  ${String.fromCharCode(171)}`
        }
        subtitle="Erstelle und verwalte Organisationen für die du Veranstaltungen erstellen möchtest."
      />
      <Container>
        <SimpleLoading isLoading={isLoading} />
        {!isLoading && (
          <div>
            <ImageInput
              previewSrc={`/v1/organizations/${id}/header_image`}
              className="org-header"
              label="Hintergrundbild hinzufügen"
              cb={e => setHeaderImage(e.target.files[0])}
              upload={addHeaderImage}
              acceptedTypes={".png,.jpg,.jpeg"}
            />
            <div id="org-infos">
              <form onSubmit={handleSubmit}>
                <h3>Allgemeine Informationen</h3>
                <div className="shadowbox meta-inf">
                  <ImageInput
                    previewSrc={`/v1/organizations/${id}/logo_image`}
                    className="org-logo"
                    label="Logo hinzufügen"
                    cb={e => setLogoImage(e.target.files[0])}
                    upload={addLogoImage}
                    acceptedTypes={".png,.jpg,.jpeg"}
                  />
                  <div className="naming">
                    <TextInput
                      value={org.shortName}
                      name="shortName"
                      onChange={e => handleInput(e, org, setOrg)}
                      label="Kurzname"
                      pattern="^\S*$"
                    />
                    <TextInput
                      value={org.fullName}
                      name="fullName"
                      onChange={e => handleInput(e, org, setOrg)}
                      label="Vollständiger Name"
                    />
                  </div>
                </div>
                <RichTextInput
                  value={org.description}
                  name="description"
                  onChange={e => handleInput(e, org, setOrg)}
                  label="Beschreibung"
                />
                <Submit
                  title="Informationen aktualisieren"
                  isSubmitting={isSubmittingGeneralInformation}
                />
              </form>
            </div>
            <Flex className="shadowbox" justifyContent="start">
              <div className="members">
                <h3>Mitglieder</h3>
                {org.members.map(member => (
                  <div className="member-entry">
                    <p>
                      {member.mail}
                      <br />
                      <sub>{member.accessLevel}</sub>
                    </p>
                    <Trash2
                      className="delete-member"
                      onClick={() => removeMember(member.mail)}
                    />
                  </div>
                ))}
                <AddMember cb={addMember} isSubmitting={isSubmittingMember} />
              </div>
            </Flex>
            <Flex className="shadowbox" justifyContent="start">
              <div className="org-settings">
                <h4>Einstellungen</h4>
                <h5>Seite löschen</h5>
                <form onSubmit={deletePage}>
                  <Submit
                    title={"Seite dauerhaft löschen"}
                    isSubmitting={false}
                  />
                </form>
              </div>
            </Flex>
          </div>
        )}
      </Container>
    </div>
  );
};

export default OrganizationEdit;
