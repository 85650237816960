export const handleInput = (event, currentState, stateChangeFunction) => {
  const { name, value } = event.target;
  const stateDelta = setToValue(currentState, value, name);
  stateChangeFunction({ ...currentState, ...stateDelta });
};

function setToValue(obj, value, path) {
  var i, tmpObj;
  path = path.split(".");
  if (path.includes("")) return obj;
  tmpObj = obj;
  for (i = 0; i < path.length - 1; i++) {
    if (obj[path[i]] === null) obj[path[i]] = {};
    obj = obj[path[i]];
  }
  obj[path[i]] = value;
  return tmpObj;
}
