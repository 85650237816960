import React from "react";
import { Flex } from "../Container";

import "./index.scss";

const Title = ({ title, subtitle, create }) => {
  return (
    <Flex justifyContent="flex-start" alignItems="center">
      <h2 className="title">
        {title}
        <sub>{subtitle}</sub>
      </h2>
      <div className="create-action">{create}</div>
    </Flex>
  );
};

export default Title;
