import React, { useState } from "react";
import "./input.scss";

const PasswordInput = props => {
  const { value, onChange, label, name, className } = props;
  const [hasValue, setHasValue] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="inputField">
      <input
        type={showPassword ? "text" : "password"}
        name={name}
        value={value}
        onChange={e => {
          setHasValue(true);
          onChange(e);
        }}
        className={`passwordField ${hasValue ? "hasValue " : ""} ${className}`}
      />
      <label>{label}</label>
      <span
        id="showPasswordIcon"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        &#128065;
      </span>
    </div>
  );
};

export default PasswordInput;
