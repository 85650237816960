import React from "react";

import { Flex } from "../../components/Container";
import "./index.scss";

const Footer = () => {
  return (
    <footer>
      <Flex>
        <ul>
          <li>
            <a target="_blank" href="https://ilex-app.de/impressum">
              Impressum
            </a>
          </li>
          <li>
            <a target="_blank" href="https://ilex-app.de/datenschutz">
              Datenschutz
            </a>
          </li>
          <li>
            <a target="_blank" href="https://ilex-app.de/faq">
              Hilfe
            </a>
          </li>
          <li>&copy; 2020 Ilex App</li>
        </ul>
      </Flex>
    </footer>
  );
};

export default Footer;
