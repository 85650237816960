import React from "react";
import { Route, useRouteMatch } from "react-router-dom";

import OrganizationList from "./scenes/OrganizationList";
import OrganizationEdit from "./scenes/OrganizationEdit";
import OrganizationAdd from "./scenes/OrganizationAdd";

const Organizations = () => {
  const match = useRouteMatch();
  return [
    <Route
      exact
      path={match.path}
      component={OrganizationList}
      key={"default"}
    />,
    <Route
      exact
      strict
      path={`${match.path}/create`}
      component={OrganizationAdd}
      key={"add"}
    />,
    <Route
      path={`${match.path}/edit/:id`}
      component={OrganizationEdit}
      key={"edit"}
    />
  ];
};

export default Organizations;
