import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { useAuth } from "./context/Auth";

import Header from "./scenes/Header";
import Footer from "./scenes/Footer";
import Auth from "./scenes/Auth";
import Verification from "./scenes/Auth/scenes/Verification";
import Confirmation from "./scenes/Auth/scenes/Confirmation";
import SignOut from "./scenes/Auth/scenes/SignOut";

import Home from "./scenes/Dashboard";
import Organizations from "./scenes/Organizations";
import Events from "./scenes/Events";
import AccountSettings from "./scenes/AccountSettings";

const PrivateRoute = ({ children, ...props }) => {
  const [authState] = useAuth();
  return (
    <Route
      {...props}
      render={({ location }) =>
        authState.isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

const Routing = () => {
  const [authState] = useAuth();
  return (
    <Router>
      {authState.isLoggedIn && <Header key="header" />}
      <main>
        <Switch>
          <PrivateRoute exact path="/">
            <Home />
          </PrivateRoute>
          <PrivateRoute path="/organizations">
            <Organizations />
          </PrivateRoute>
          <PrivateRoute path="/events">
            <Events />
          </PrivateRoute>
          <PrivateRoute path="/settings">
            <AccountSettings />
          </PrivateRoute>
          <PrivateRoute path="/logout">
            <SignOut />
          </PrivateRoute>
          <Route exact path="/auth" component={Auth} />
          <Route path="/verification/:token" component={Verification} />
          <Route path="/confirm/:token" component={Confirmation} />
        </Switch>
      </main>
      {authState.isLoggedIn && <Footer key="header" />}
    </Router>
  );
};

export default Routing;
