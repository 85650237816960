import React, { useState } from "react";
import Title from "../../components/Title";
import { Submit } from "../../components/Button";

import { Container, Flex } from "../../components/Container";
import { LongTextInput } from "../../components/Input";

import { handleInput } from "../../utils/index";

import { toast } from "react-toastify";

const Dashboard = () => {
  const styles = { alignSelf: "stretch", flex: "0 50%" };
  const [bugReport, setBugReport] = useState({});
  const [proposal, setProposal] = useState({});
  const [isSubmittingProposal, setIsSubmittingProposal] = useState(false);
  const [isSubmittingBug, setIsSubmittingBug] = useState(false);

  const submitReport = (event, type) => {
    event.preventDefault();
    type === "BUG" ? setIsSubmittingBug(true) : setIsSubmittingProposal(true);
    const report = type === "BUG" ? { ...bugReport } : { ...proposal };
    fetch(`/v1/reports/${type.toLowerCase()}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token")
      }),
      body: JSON.stringify(report)
    }).then(() => {
      setIsSubmittingProposal(false);
      setIsSubmittingBug(false);
      toast.success("Vielen Dank für deine Meldung!");
    });
  };
  return (
    <div>
      <Title
        title="Ilex App"
        subtitle="Die App für die Gemeinde Hüllhorst. Erstelle Profile und Veranstaltungen für Unternehmen, Vereine oder andere Organisationen."
        key="title"
      />
      <Container key="content">
        <div className="shadowbox">
          <p>Hallo!</p>
          <p>
            Die Ilex App befindet sich zur Zeit in einer offenen Beta Phase. Das
            bedeutet, dass Fehler passieren können. Um bei der Weiterentwicklung
            aktiv mitzuwirken, kannst du sobald du einen Fehler bemerkst einen
            Fehlerbericht senden. Vielen Dank!
          </p>
          <Flex>
            <div style={styles}>
              <form onSubmit={e => submitReport(e, "PROPOSAL")}>
                <LongTextInput
                  label="Vorschlag"
                  minLength={10}
                  value={proposal.message}
                  onChange={e => handleInput(e, proposal, setProposal)}
                  name="message"
                />
                <Submit
                  title={"Vorschlag absenden"}
                  isSubmitting={isSubmittingProposal}
                />
              </form>
            </div>
            <div style={styles}>
              <form onSubmit={e => submitReport(e, "BUG")}>
                <LongTextInput
                  label="Fehlermeldung &#128028;"
                  minLength={10}
                  value={bugReport.message}
                  onChange={e => handleInput(e, bugReport, setBugReport)}
                  name="message"
                />
                <Submit
                  title={"Fehlermeldung absenden"}
                  isSubmitting={isSubmittingBug}
                />
              </form>
            </div>
          </Flex>
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
