import React from "react";

import { Link } from "react-router-dom";
import { Edit3 } from "react-feather";
import "./index.scss";

const ListEntry = ({ event }) => {
  const startDate = new Date(event.start);
  return (
    <div className="list-entry" key={event.id}>
      <div className="infos">
        <h3>{event.title}</h3>
        <p>
          {!isNaN(startDate.getDate()) &&
            startDate.getDate() +
              "/" +
              (startDate.getMonth() + 1) +
              "/" +
              startDate.getFullYear() +
              " - "}
          {event.organizedBy.shortName}
          {event.published
            ? " - Veranstaltung veröffentlicht"
            : " - Veranstaltung noch nicht veröffentlicht"}
          {event.canceled ? " (Die Veranstaltung wurde abgesagt)" : ""}
        </p>
      </div>
      <div className="actions">
        <Link to={`/events/edit/${event.id}`}>
          <Edit3 />
        </Link>
      </div>
    </div>
  );
};

export default ListEntry;
