import React from "react";

import { Link } from "react-router-dom";
import { Edit3 } from "react-feather";
import "./index.scss";

const ListEntry = ({ organization }) => {
  var logo = {
    backgroundImage: `url(/v1/organizations/${organization.id}/logo_image)`
  };
  return (
    <div className="list-entry" key={organization.key}>
      <div className="org-logo" style={logo} />
      <div className="infos">
        <h3>{organization.shortName}</h3>
        <p>{organization.fullName}</p>
      </div>
      <div className="actions">
        <Link to={`/organizations/edit/${organization.id}`}>
          <Edit3 />
        </Link>
      </div>
    </div>
  );
};

export default ListEntry;
