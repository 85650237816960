import React, { useEffect } from "react";
import { useAuth } from "../../../../context/Auth";

const SignOut = () => {
  const [auth, dispatch] = useAuth();
  useEffect(() => {
    dispatch({ type: "AUTH_LOGOUT" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div></div>;
};

export default SignOut;
