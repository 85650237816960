import React, { useState } from "react";
import "./input.scss";

const TextInput = props => {
  const {
    value,
    onChange,
    label,
    name,
    className,
    pattern,
    minLength = 0,
    maxLength = 255
  } = props;
  const [hasValue, setHasValue] = useState(
    value !== null && value !== undefined
  );
  const [validity, setValidity] = useState({ valid: true });

  return (
    <div className="inputField">
      <input
        type="text"
        name={name}
        value={value}
        pattern={pattern}
        required={minLength > 0}
        minLength={minLength}
        maxLength={maxLength}
        onChange={e => {
          setValidity(e.target.validity);
          setHasValue(true);
          onChange(e);
        }}
        className={`${hasValue ? "hasValue " : ""} 
        ${validity.valid ? "" : "hasError "} 
        ${className}`}
      />
      <label>{label}</label>
    </div>
  );
};

export default TextInput;
