import React from "react";
import Navbar from "./scenes/Navbar";
import { Flex } from "../../components/Container";

import logo from "../../logo.png";
import "./index.scss";
import {useAuth} from "../../context/Auth";

const Header = () => {
  const [authState] = useAuth();
  return authState.isLoggedIn ? (<header>
      <Flex alignItems="center" justifyContent="space-between">
        <img
          id="app-logo"
          src={logo}
          alt="Ilex-App Logo. Stilisiertes Ilex Blatt mit 3 Früchten."
        ></img>
        <Navbar />
      </Flex>
    </header>
  ) : null;
};

export default Header;
