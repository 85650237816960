import React, { useState, useEffect } from "react";
import Title from "../../../../components/Title";
import { Container, Flex } from "../../../../components/Container";
import { SimpleLoading } from "../../../../components/Loading";
import {
  TextInput,
  DateInput,
  RichTextInput,
  LongTextInput,
  ImageInput
} from "../../../../components/Input";
import Location from "../components/Location";
import { handleInput } from "../../../../utils";
import { useParams } from "react-router-dom";
import "./index.scss";
import { toast } from "react-toastify";
import Submit from "../../../../components/Button/Submit";

const EventEdit = () => {
  const { id } = useParams();
  const [headerImage, setHeaderImage] = useState([]);
  const [form, setForm] = useState({
    location: {
      name: "",
      street: "",
      houseNumber: "",
      zip: "32609",
      city: "Hüllhorst"
    }
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetch(`/v1/events/${id}?editable=true`, {
      headers: new Headers({ Authorization: localStorage.getItem("token") })
    })
      .then(res => res.json())
      .then(data => setForm(data))
      .then(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = event => {
    setIsSubmitting(true);
    event.preventDefault();
    fetch(`/v1/events/${id}`, {
      headers: new Headers({
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json"
      }),
      method: "PUT",
      body: JSON.stringify({
        ...form
      })
    }).then(res => {
      setIsSubmitting(false);
      if (res.status === 200) {
        toast.success("Die Veranstaltung wurde aktualisiert.");
      }
    });
  };

  const publishEvent = () => {
    fetch(`/v1/events/${id}/published`, {
      headers: new Headers({
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json"
      }),
      method: "PUT"
    }).then(res => {
      if (res.status === 200) {
        toast.info("Die Veranstaltung wurde veröffentlicht.");
        setForm({ ...form, published: true });
      }
    });
  };

  const cancelEvent = () => {
    fetch(`/v1/events/${id}/canceled`, {
      headers: new Headers({
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json"
      }),
      method: "PUT"
    }).then(res => {
      if (res.status === 200) {
        toast.info("Die Veranstaltung wurde abgesagt.");
        setForm({ ...form, canceled: true });
      }
    });
  };

  const addHeaderImage = () => {
    var formData = new FormData();
    formData.append("header_image", headerImage);
    fetch(`/v1/events/${id}/header_image`, {
      headers: new Headers({
        Authorization: localStorage.getItem("token")
      }),
      method: "POST",
      body: formData
    }).then(res => {
      if (res.status === 200) {
        toast.info("Das Titelbild wurde erfolgreich hochgeladen.");
        setIsLoading(true);
        setIsLoading(false);
      } else {
        toast.error(
          "Beim hochladen des Titelbilds ist ein Fehler aufgetreten."
        );
      }
    });
  };

  return (
    <div>
      <Title
        title={
          isLoading
            ? "Bearbeite die Veranstaltung "
            : `Bearbeite die Veranstaltung ${String.fromCharCode(187)} ${
                form.title
              } ${String.fromCharCode(171)}`
        }
        subtitle={
          isLoading
            ? ""
            : form.published
            ? "Diese Veranstaltung ist veröffentlicht."
            : "Diese Veranstaltung ist als Entwurf gespeichert und wurde noch nicht veröffentlicht."
        }
      />
      <Container>
        <SimpleLoading isLoading={isLoading} />
        {!isLoading && (
          <Container>
            <ImageInput
              previewSrc={`/v1/events/${id}/header_image`}
              className="event-header"
              label="Hintergrundbild hinzufügen"
              cb={e => setHeaderImage(e.target.files[0])}
              upload={addHeaderImage}
              acceptedTypes={".png,.jpg,.jpeg"}
            />
            <form onSubmit={handleSubmit}>
              <h3>Allgemeine Informationen</h3>
              <Flex alignItems="start" className="shadowbox">
                <div style={{ flex: "0 50%" }}>
                  <TextInput
                    value={form.title}
                    name="title"
                    onChange={e => handleInput(e, form, setForm)}
                    label="Titel der Veranstaltung"
                  />
                  <TextInput
                    value={form.urlToExternalPage}
                    name="urlToExternalPage"
                    onChange={e => handleInput(e, form, setForm)}
                    label="Link zur Veranstaltungsseite"
                  />
                  <DateInput
                    value={form.start}
                    name="start"
                    onChange={e => handleInput(e, form, setForm)}
                    label="Start der Veranstaltung"
                  />
                  <DateInput
                    value={form.end}
                    name="end"
                    onChange={e => handleInput(e, form, setForm)}
                    label="Ende der Veranstaltung"
                  />
                </div>
                <div style={{ flex: "0 50%" }}>
                  <LongTextInput
                    value={form.shortDescription}
                    name="shortDescription"
                    onChange={e => handleInput(e, form, setForm)}
                    label="Kurzbeschreibung der Veranstaltung"
                  />
                </div>
              </Flex>
              <h3>Veranstaltungsbeschreibung</h3>
              <RichTextInput
                value={form.fullDescription}
                name="fullDescription"
                onChange={e => handleInput(e, form, setForm)}
                label="Beschreibung"
              />
              <h3>Veranstaltungsort</h3>
              <Flex className="shadowbox" justifyContent="start">
                <Location state={form} setState={setForm} />
              </Flex>
              <h3>Veranstalter</h3>
              <Flex
                className="shadowbox"
                justifyContent="start"
                direction="column"
              >
                <label>Veranstalter</label>
                <p>{form.organizedBy.shortName}</p>
              </Flex>
              <Submit
                title="Veranstaltung aktualisieren"
                isSubmitting={isSubmitting}
              />
            </form>
            <h3>Weitere Einstellungen</h3>
            <Flex className="shadowbox" justifyContent="start">
              {!form.published && (
                <button className="submit" onClick={publishEvent}>
                  Veranstaltung veröffentlichen
                </button>
              )}
              {form.published && !form.canceled && (
                <button className="submit" onClick={cancelEvent}>
                  Veranstaltung absagen
                </button>
              )}
            </Flex>
          </Container>
        )}
      </Container>
    </div>
  );
};

export default EventEdit;
