import React, { useState } from "react";
import { TextInput, PasswordInput } from "../../../../components/Input";
import "./index.scss";
import { handleInput } from "../../../../utils/index";

const SignUp = () => {
  const [form, setForm] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);
  const [error, setError] = useState({});
  const handleSubmit = async event => {
    event.preventDefault();
    fetch("/v1/sign-up", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify({ mail: form.mail, password: form.password })
    }).then(res => {
      switch (res.status) {
        case 200:
          break;
        case 409:
          setError({
            message: "Die E-Mail Adresse ist bereits vergeben.",
            code: 409
          });
          break;
        default:
          setError({
            message: "Ein unbekannter Fehler ist aufgetreten.",
            code: res.status
          });
      }
    });
    setIsSubmited(true);
  };
  return [
    <form onSubmit={handleSubmit}>
      <TextInput
        value={form.mail}
        name="mail"
        onChange={e => handleInput(e, form, setForm)}
        label="E-Mail"
      />
      <PasswordInput
        name="password"
        value={form.password}
        onChange={e => handleInput(e, form, setForm)}
        label="Passwort"
      />
      <PasswordInput
        value={form.verifiedPassword}
        name="verifiedPassword"
        onChange={e => handleInput(e, form, setForm)}
        label="Passwort wiederholen"
      />
      <input
        type="submit"
        disabled={form.password !== form.verifiedPassword || isSubmited}
      />
    </form>,
    <div>{isSubmited && error.message !== "" && <p>{error.message}</p>}</div>,
    <div>
      {isSubmited && error.message === undefined && (
        <p>
          Danke für deine Anmeldung. Wir haben dir gerade eine E-Mail geschickt,
          um deine Anmeldung zu bestätigen.
        </p>
      )}
    </div>
  ];
};

export default SignUp;
