import React from "react";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import "./input.scss";

const TextInput = props => {
  const { value, onChange, label, name } = props;
  const specialStyle = {
    padding: 0,
    width: "100%"
  };
  return (
    <div>
      <h4>{label}</h4>
      <div className="shadowbox" style={specialStyle}>
        <div className="inputField longText">
          <ReactQuill
            value={value || ""}
            name={name}
            onChange={value =>
              onChange({ target: { name: name, value: value } })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default TextInput;
