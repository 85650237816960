import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import { Container } from "../../components/Container";
import { TextInput, PasswordInput } from "../../components/Input";

import { SimpleLoading } from "../../components/Loading";

import { handleInput } from "../../utils";
import Submit from "../../components/Button/Submit";

const AccountSettings = () => {
  const [account, setAccount] = useState({});
  const [changePasswd, setChangePasswd] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmittingName, setIsSubmittingName] = useState(false);
  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false);
  useEffect(() => {
    fetch(`/v1/account`, {
      headers: new Headers({ Authorization: localStorage.getItem("token") })
    })
      .then(res => res.json())
      .then(data => setAccount(data))
      .then(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeName = event => {
    event.preventDefault();
    setIsSubmittingName(true);
    fetch(`/v1/account/name`, {
      headers: new Headers({
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json"
      }),
      method: "PUT",
      body: JSON.stringify({
        firstName: account.firstName,
        lastName: account.lastName
      })
    }).then(() => {
      setIsSubmittingName(false);
    });
  };

  const changePassword = event => {
    event.preventDefault();
    setIsSubmittingPassword(true);
    fetch(`/v1/account/password`, {
      headers: new Headers({
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json"
      }),
      method: "PUT",
      body: JSON.stringify({
        oldPassword: changePasswd.old,
        newPassword: changePasswd.new
      })
    }).then(() => setIsSubmittingPassword(false));
  };
  return (
    <div>
      <Title
        title="Einstellungen"
        subtitle={`Verwalte deinen persönlichen Account.`}
      />
      <Container>
        <SimpleLoading isLoading={isLoading} />
        {!isLoading && [
          <form onSubmit={changeName}>
            <h3>Allgemeine Einstellungen</h3>
            <div className="shadowbox">
              <TextInput
                name="firstName"
                value={account.firstName}
                onChange={e => handleInput(e, account, setAccount)}
                label="Vorname"
              />
              <TextInput
                name="lastName"
                value={account.lastName}
                onChange={e => handleInput(e, account, setAccount)}
                label="Nachname"
              />
              <Submit title={"Namen ändern"} isSubmitting={isSubmittingName} />
            </div>
          </form>,
          <form onSubmit={changePassword}>
            <h3>Passwort ändern</h3>
            <div className="shadowbox">
              <PasswordInput
                name="old"
                value={changePasswd.old}
                onChange={e => handleInput(e, changePasswd, setChangePasswd)}
                label="Aktuelles Passwort"
              />
              <PasswordInput
                name="new"
                value={changePasswd.new}
                onChange={e => handleInput(e, changePasswd, setChangePasswd)}
                label="Neues Passwort"
              />
              <PasswordInput
                name="verify"
                value={changePasswd.verify}
                onChange={e => handleInput(e, changePasswd, setChangePasswd)}
                label="Passwort erneut eingeben"
              />
              <Submit
                title="Passwort ändern"
                isSubmitting={isSubmittingPassword}
              />
            </div>
          </form>
        ]}
      </Container>
    </div>
  );
};

export default AccountSettings;
