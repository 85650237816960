import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { PasswordInput } from "../../../../components/Input";
import { Flex } from "../../../../components/Container";
import { handleInput } from "../../../../utils/index";

const Confirmation = () => {
  const { token } = useParams();
  const [form, setForm] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    fetch("/v1/account/password?confirmation=true", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify({ resetToken: token, newPassword: form.password })
    }).then(() => {
      setIsSubmited(true);
    });
  };
  return (
    <Flex direction="column">
      <form onSubmit={handleSubmit} justifyContent="start">
        <h4>Passwort zurücksetzten</h4>
        <PasswordInput
          label="Neues Passwort"
          name="password"
          value={form.password}
          onChange={e => handleInput(e, form, setForm)}
        />
        <input type="submit" />
      </form>
      <div>
        {isSubmited && (
          <p>
            Dein Passwort wurde zurückgesetzt. <a href="/auth">Zum Login.</a>
          </p>
        )}
      </div>
    </Flex>
  );
};

export default Confirmation;
