import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import { SimpleLoading } from "../Loading";
import { Check, X } from "react-feather";

const Submit = ({
  title = "",
  isSubmitting = false,
  className = "",
  error = false
}) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    if (!isSubmitting && !isInitialRender) {
      setHasSubmitted(true);
      setTimeout(() => setHasSubmitted(false), 2000);
    }
    if (isInitialRender) {
      setIsInitialRender(false);
    }
  }, [isSubmitting]);
  useEffect(() => {
    if (ref.current !== null) {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
    }
  });
  return (
    <div
      className={`${className} btn-submit`}
      style={{ width: width, height: height }}
    >
      {!isSubmitting && !hasSubmitted && (
        <input
          ref={ref}
          type="submit"
          value={title}
          className={`btn-submit ${isSubmitting ? "submiting " : ""}`}
        />
      )}
      {isSubmitting && !hasSubmitted && <SimpleLoading isLoading={true} />}
      {hasSubmitted && !error && (
        <div className="check">
          <Check />
        </div>
      )}
      {hasSubmitted && error && (
        <div className="check">
          <X />
        </div>
      )}
    </div>
  );
};

export default Submit;
