import React, { useReducer, useContext, useEffect } from "react";

import { AuthContext } from "./context";

const defaultState = () => {
  const isLoggedIn = localStorage.getItem("token") !== null;
  return {
    userInfo: {},
    error: null,
    isLoggedIn: isLoggedIn
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "AUTH_ERROR":
      return {
        ...state,
        isLoggedIn: false,
        error: {
          message: action.payload.message,
          status: action.payload.status
        }
      };
    case "AUTH_LOGIN": {
      localStorage.setItem("token", action.payload);
      return {
        ...state,
        isReady: true,
        isLoggedIn: true
      };
    }
    case "AUTH_LOGOUT": {
      localStorage.removeItem("token");
      return {
        ...state,
        isLoggedIn: false,
        userInfo: {},
        error: null
      };
    }
    case "USER_INFO": {
      return {
        ...state,
        userInfo: action.payload
      };
    }
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState());
  useEffect(() => {
    if (state.isLoggedIn) {
      fetch(`/v1/account`, {
        headers: new Headers({ Authorization: localStorage.getItem("token") })
      })
        .then(res => {
          if (res.status !== 200) throw new Error();
          return res;
        })
        .then(res => res.json())
        .then(data => {
          dispatch({ type: "USER_INFO", payload: data });
        })
        .catch(error => {
          dispatch({
            type: "AUTH_ERROR",
            payload: { message: error.message, status: error.status }
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export default AuthProvider;
