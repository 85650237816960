import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Flex } from "../../../../components/Container";
import { SimpleLoading } from "../../../../components/Loading";

const Verification = () => {
  const { token } = useParams();
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`/v1/verification/${token}`).then(res => {
      if (res.status === 200) {
        setIsVerified(true);
      }
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Flex>
      <SimpleLoading isLoading={isLoading} />
      {isVerified && (
        <p>
          Dein Account wurde bestätigt! <a href="/">Weiter zur Startseite</a>
        </p>
      )}
      {!isVerified && <p>Dein Account konnte nicht bestätigt werden.</p>}
    </Flex>
  );
};

export default Verification;
