import React, { useState } from "react";
import Title from "../../../../components/Title";
import { Container } from "../../../../components/Container";
import { TextInput } from "../../../../components/Input";
import { handleInput } from "../../../../utils/index";

import { toast } from "react-toastify";
import Submit from "../../../../components/Button/Submit";

const OrganizationAdd = ({ history }) => {
  const [form, setForm] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async event => {
    event.preventDefault();
    setIsSubmitting(true);
    fetch("/v1/organizations", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token")
      }),
      body: JSON.stringify({ ...form })
    })
      .then(res => {
        if (res.status === 200) {
          toast.success("Die Seite wurde erfolgreich angelegt.");
          setIsSubmitting(false);
          return res.json();
        } else {
          toast.error("Ein Fehler ist aufgetreten. Fehler: " + res.status);
          Promise.reject();
        }
      })
      .catch(error => {
        console.error(error)
      })
      .then(data => {
        if (data) {
          history.push(`/organizations/edit/${data.id}`);
        } else {
          history.push(`/organizations`);
          toast.error(
            "Es sieht so aus, als ob deine Seite angelegt wurde. Leider ist bei der Weiterleitung ein Fehler passiert."
          );
        }
      });
  };
  return (
    <div>
      <Title
        title="Erstelle eine neue Seite"
        subtitle="Erstelle eine Seite für eine Organisation (Verein, Partei, Firma) die du vertrittst."
      />
      <Container>
        <form onSubmit={handleSubmit}>
          <TextInput
            value={form.shortName}
            name="shortName"
            onChange={e => handleInput(e, form, setForm)}
            label="Kurzname / Anzeigename"
            pattern="^[\S.]+$"
            minLength={1}
          />
          <TextInput
            value={form.fullName}
            name="fullName"
            onChange={e => handleInput(e, form, setForm)}
            label="Vollständiger Name"
            pattern="[A-Za-z0-9_- ]*"
            minLength={1}
          />
          <Submit
            title={"Neue Organisation erstellen"}
            isSubmitting={isSubmitting}
          />
        </form>
      </Container>
    </div>
  );
};

export default OrganizationAdd;
