import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Plus } from "react-feather";
import Title from "../../../../components/Title";
import { Container } from "../../../../components/Container";
import { SimpleLoading } from "../../../../components/Loading";

import ListEntry from "../../components/ListEntry";

const OrganizationList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`/v1/organizations?editable=true`, {
      headers: new Headers({ Authorization: localStorage.getItem("token") })
    })
      .then(res => res.json())
      .then(data => setData(data))
      .then(() => setIsLoading(false));
  }, []);
  return (
    <div>
      <Title
        title="Seiten"
        subtitle="Erstelle und verwalte Organisationen für die du Veranstaltungen erstellen möchtest."
        key="title"
        create={
          <Link to="/organizations/create">
            <Plus />
          </Link>
        }
      />
      <Container key="content">
        <SimpleLoading isLoading={isLoading} />
        {data.map(entry => (
          <ListEntry organization={entry} key={entry.id} />
        ))}
      </Container>
    </div>
  );
};

export default OrganizationList;
