import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../context/Auth";

import "./style.scss";

import SignIn from "./scenes/SignIn";
import SignUp from "./scenes/SignUp";
import ForgotPassword from "./scenes/ForgotPassword";

import logo from "../../logo.png";

const Auth = () => {
  const [selected, setSelected] = useState("signin");
  const [authState] = useAuth();
  return authState.isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <div id="body">
      <div id="auth-form">
        <img
          src={logo}
          className="auth-logo"
          alt="Ilex-App Logo. Stilisiertes Ilex Blatt mit 3 Früchten."
        />
        <nav>
          <ul>
            <li
              className={selected === "signin" ? "active " : ""}
              onClick={() => setSelected("signin")}
            >
              Anmelden
            </li>
            <li
              className={selected === "signup" ? "active " : ""}
              onClick={() => setSelected("signup")}
            >
              Registrieren
            </li>
            <li
              className={selected === "forgotpassword" ? "active " : ""}
              onClick={() => setSelected("forgotpassword")}
            >
              Passwort vergessen
            </li>
          </ul>
        </nav>
        {selected === "signin" && <SignIn />}
        {selected === "signup" && <SignUp />}
        {selected === "forgotpassword" && <ForgotPassword />}
      </div>
      <div id="gallery">
        <p>
          <a href="https://commons.wikimedia.org/w/index.php?curid=5872998">
            Von Dietrich Korff - www.muehlenflieger.de, CC BY-SA 3.0
          </a>
        </p>
      </div>
    </div>
  );
};

export default Auth;
