import React, { useState } from "react";
import { TextInput, PasswordInput } from "../../../../components/Input";

import "./index.scss";
import { handleInput } from "../../../../utils/index";
import { useAuth } from "../../../../context/Auth";

const SignIn = () => {
  const [auth, dispatch] = useAuth();
  const [form, setForm] = useState({});
  const [error, setError] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    fetch("/v1/login", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify({ mail: form.mail, password: form.password })
    }).then(res => {
      if (res.status === 200) {
        dispatch({
          type: "AUTH_LOGIN",
          payload: res.headers.get("Authorization")
        });
      } else {
        setError(true);
        setTimeout(() => setError(false), 1000);
      }
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        value={form.mail}
        name="mail"
        onChange={e => handleInput(e, form, setForm)}
        label="E-Mail"
        className={error ? "error " : ""}
      />
      <PasswordInput
        value={form.password}
        name="password"
        onChange={e => handleInput(e, form, setForm)}
        label="Passwort"
        className={error ? "error " : ""}
      />
      <input type="submit" />
    </form>
  );
};

export default SignIn;
